import React, { Suspense } from "react";
import { Image, Button, Space, Skeleton, Tooltip as Tooltip1, Row, Tag, Col } from "antd";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import { useSelector, useDispatch } from "react-redux";
import ViewCustomized from "../assets/images/icons/ViewCustomized.png";
import { showFilterModal, hideFilterModal } from "../redux/filterModal";
import { useEffect, useState } from "react";
import { ApifordataPost } from "../pages/Helper/ApiForGetData";
const FilterModal = React.lazy(() => import("./FilterModal"));

ChartJS.register(ArcElement, Tooltip, Legend);

function Home(props) {
  const dispatch = useDispatch();
  const { isModalOpen } = useSelector((state) => state.filtermodal);

  const filterValues = useSelector((state) => state.filterValue);
  const [mychain, setMychain] = useState([]);
  const [topchain, setTopchain] = useState([]);
  const [loader, setLoader] = useState(false);
  const [name, setName] = useState("Home");
  const [calApi, setCalApi] = useState(0);
  const { Timescales, MarketSegment, CompetitiveSet, ComparisionType } = filterValues;

  useEffect(() => {
    getDashboardData();
  }, [calApi]);

  const getDashboardData = async () => {
    setLoader(true);
    let payLoad = {
      filters: {
        Timescale: filterValues.ComparisionType,
        Market_Segment: filterValues.MarketSegment,
        Competitive_Set: filterValues.CompetitiveSet,
        Select_Date: filterValues.Timescales,
      },

      email: localStorage.getItem("email"),
    };
    let response = await ApifordataPost("/dashboard/", payLoad);
    if (response.message == "Invalid token" || response.message == "Token has expired") {
      localStorage.clear();
      window.location.reload();
    }

    if (response?.data) {
      setMychain(response.my_data);
      setTopchain(response.data);
      setLoader(false);
    }
  };
  if (mychain?.donut_Visits >= 0) {
    var visits1 = {
      datasets: [
        {
          data: [mychain.donut_Visits, 100 - mychain.donut_Visits],
          backgroundColor: ["rgb(163,123,247)", "#E5E5E5"],
        },
      ],
    };

    var visits1lable = {
      id: "visits1lable",
      afterDatasetsDraw(chart) {
        const { ctx, data } = chart;

        const centerX = chart.getDatasetMeta(0).data[0].x;
        const centerY = chart.getDatasetMeta(0).data[0].y;
        ctx.save();
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = '16px "Helvetica Neue", Helvetica, Arial, sans-serif';
        ctx.fillText(`${mychain?.Visits}`, centerX, centerY);
      },
    };
  } else {
    var visits1 = {
      datasets: [
        {
          data: [100 - -mychain?.donut_Visits, mychain?.donut_Visits],
          backgroundColor: ["#E5E5E5", "rgb(163,123,247)"],
        },
      ],
    };

    var visits1lable = {
      id: "visits1lable",
      afterDatasetsDraw(chart) {
        const { ctx, data } = chart;

        const centerX = chart.getDatasetMeta(0).data[0].x;
        const centerY = chart.getDatasetMeta(0).data[0].y;
        ctx.save();
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = '16px "Helvetica Neue", Helvetica, Arial, sans-serif';
        ctx.fillText(`${mychain?.Visits}`, centerX, centerY);
      },
    };
  }
  if (mychain?.donut_L4L >= 0) {
    var L4L1 = {
      datasets: [
        {
          data: [mychain.donut_L4L, 100 - mychain.donut_L4L],
          backgroundColor: ["rgb(141, 208, 222)", "#E5E5E5"],
        },
      ],
    };

    var L4L1Lable = {
      id: "visits1lable",

      afterDatasetsDraw(chart) {
        const { ctx, data } = chart;

        const centerX = chart.getDatasetMeta(0).data[0].x;
        const centerY = chart.getDatasetMeta(0).data[0].y;
        ctx.save();
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = '16px "Helvetica Neue", Helvetica, Arial, sans-serif';
        ctx.fillText(`${mychain.L4L}`, centerX, centerY);
      },
    };
  } else {
    var L4L1 = {
      datasets: [
        {
          data: [100 - -mychain?.donut_L4L, mychain?.donut_L4L],
          backgroundColor: ["#E5E5E5", "rgb(141, 208, 222)"],
        },
      ],
    };

    var L4L1Lable = {
      id: "visits1lable",

      afterDatasetsDraw(chart) {
        const { ctx, data } = chart;

        const centerX = chart.getDatasetMeta(0).data[0].x;
        const centerY = chart.getDatasetMeta(0).data[0].y;
        ctx.save();
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = '16px "Helvetica Neue", Helvetica, Arial, sans-serif';
        ctx.fillText(`${mychain.L4L}`, centerX, centerY);
      },
    };
  }

  if (mychain?.donut_Outlets >= 0) {
    var Opening1 = {
      datasets: [
        {
          data: [mychain.donut_Outlets, 100 - mychain.donut_Outlets],
          backgroundColor: ["rgb(247, 104, 112)", "#E5E5E5"],
        },
      ],
    };

    var Opening1Lable = {
      id: "visits1lable",
      afterDatasetsDraw(chart) {
        const { ctx, data } = chart;
        const centerX = chart.getDatasetMeta(0).data[0].x;
        const centerY = chart.getDatasetMeta(0).data[0].y;
        ctx.save();
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = '16px "Helvetica Neue", Helvetica, Arial, sans-serif';
        ctx.fillText(`${mychain?.Outlets}`, centerX, centerY);
      },
    };
  } else {
    var Opening1 = {
      datasets: [
        {
          data: [100 - -mychain?.donut_Outlets, mychain?.donut_Outlets],
          backgroundColor: ["#E5E5E5", "rgb(247, 104, 112)"],
        },
      ],
    };

    var Opening1Lable = {
      id: "visits1lable",
      afterDatasetsDraw(chart) {
        const { ctx, data } = chart;
        const centerX = chart.getDatasetMeta(0).data[0].x;
        const centerY = chart.getDatasetMeta(0).data[0].y;
        ctx.save();
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = '16px "Helvetica Neue", Helvetica, Arial, sans-serif';
        ctx.fillText(`${mychain?.Outlets}`, centerX, centerY);
      },
    };
  }

  if (mychain?.donut_Prices >= 0) {
    var Prices1 = {
      datasets: [
        {
          data: [mychain.donut_Prices, 100],
          backgroundColor: ["rgb(29, 185, 222)", "#E5E5E5"],
        },
      ],
    };

    var Prices1Lable = {
      id: "visits1lable",
      afterDatasetsDraw(chart) {
        const { ctx, data } = chart;
        const centerX = chart.getDatasetMeta(0).data[0].x;
        const centerY = chart.getDatasetMeta(0).data[0].y;
        ctx.save();
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = '16px "Helvetica Neue", Helvetica, Arial, sans-serif';
        ctx.fillText(`${mychain?.Prices}`, centerX, centerY);
      },
    };
  } else {
    var Prices1 = {
      datasets: [
        {
          data: [100 - -mychain?.donut_Prices, mychain?.donut_Prices],
          backgroundColor: ["#E5E5E5", "rgb(29, 185, 222)"],
        },
      ],
    };

    var Prices1Lable = {
      id: "visits1lable",
      afterDatasetsDraw(chart) {
        const { ctx, data } = chart;
        const centerX = chart.getDatasetMeta(0).data[0].x;
        const centerY = chart.getDatasetMeta(0).data[0].y;
        ctx.save();
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = '16px "Helvetica Neue", Helvetica, Arial, sans-serif';
        ctx.fillText(`${mychain?.Prices}`, centerX, centerY);
      },
    };
  }

  if (mychain?.donut_Promo >= 0) {
    var Promo = {
      datasets: [
        {
          label: `%`,
          data: [mychain.donut_Promo, 100 - mychain.donut_Promo],
          backgroundColor: ["rgb(79, 231, 177)", "#E5E5E5"],
        },
      ],
    };

    var PromoLable = {
      id: "PromoLable",
      afterDatasetsDraw(chart) {
        const { ctx, data } = chart;
        const centerX = chart.getDatasetMeta(0).data[0].x;
        const centerY = chart.getDatasetMeta(0).data[0].y;
        ctx.save();
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = '16px "Helvetica Neue", Helvetica, Arial, sans-serif';
        ctx.fillText(`${mychain?.absolute_Promo}`, centerX, centerY);
      },
    };
  } else {
    var Promo = {
      datasets: [
        {
          label: `%`,
          data: [`${100 - -mychain?.donut_Promo}`, mychain?.donut_Promo],
          backgroundColor: ["#E5E5E5", "rgb(79, 231, 177)"],
        },
      ],
    };

    var PromoLable = {
      id: "PromoLable",
      afterDatasetsDraw(chart) {
        const { ctx, data } = chart;
        const centerX = chart.getDatasetMeta(0).data[0].x;
        const centerY = chart.getDatasetMeta(0).data[0].y;
        ctx.save();
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = '16px "Helvetica Neue", Helvetica, Arial, sans-serif';
        ctx.fillText(`${mychain?.absolute_Promo}`, centerX, centerY);
      },
    };
  }

  if (mychain?.donut_Product >= 0) {
    var Product1 = {
      datasets: [
        {
          label: `%`,
          data: [mychain.donut_Product, 100 - mychain.donut_Product],
          backgroundColor: ["rgb(251, 185, 65)", "#E5E5E5"],
        },
      ],
    };

    var Product1Lable = {
      id: "Product1Lable",
      afterDatasetsDraw(chart) {
        const { ctx, data } = chart;
        const centerX = chart.getDatasetMeta(0).data[0].x;
        const centerY = chart.getDatasetMeta(0).data[0].y;
        ctx.save();
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = '16px "Helvetica Neue", Helvetica, Arial, sans-serif';
        ctx.fillText(`${mychain?.absolute_Product}`, centerX, centerY);
      },
    };
  } else {
    var Product1 = {
      datasets: [
        {
          data: [`${100 - -mychain?.donut_Product}`, mychain?.donut_Product],
          backgroundColor: ["#E5E5E5", "rgb(251, 185, 65)"],
          label: `%`,
        },
      ],
    };

    var Product1Lable = {
      id: "Product1Lable",
      afterDatasetsDraw(chart) {
        const { ctx, data } = chart;
        const centerX = chart.getDatasetMeta(0).data[0].x;
        const centerY = chart.getDatasetMeta(0).data[0].y;
        ctx.save();
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = '16px "Helvetica Neue", Helvetica, Arial, sans-serif';
        ctx.fillText(`${mychain?.absolute_Product}`, centerX, centerY);
      },
    };
  }
  if (topchain?.donut_Visits >= 0) {
    var Visits2 = {
      datasets: [
        {
          data: [topchain.donut_Visits, 100 - topchain.donut_Visits],
          backgroundColor: ["rgb(163,123,247)", "#E5E5E5"],
        },
      ],
    };

    var Visits2Lable = {
      id: "Visits1lable",
      afterDatasetsDraw(chart) {
        const { ctx, data } = chart;

        const centerX = chart.getDatasetMeta(0).data[0].x;
        const centerY = chart.getDatasetMeta(0).data[0].y;
        ctx.save();
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = '16px "Helvetica Neue", Helvetica, Arial, sans-serif';
        ctx.fillText(`${topchain?.Visits}`, centerX, centerY);
      },
    };
  } else {
    var Visits2 = {
      datasets: [
        {
          data: [100 - -topchain?.donut_Visits, topchain?.donut_Visits],
          backgroundColor: ["#E5E5E5", "rgb(163,123,247)"],
        },
      ],
    };

    var Visits2Lable = {
      id: "Visits2Lable",
      afterDatasetsDraw(chart, args, plugin) {
        const { ctx, data } = chart;

        const centerX = chart.getDatasetMeta(0).data[0].x;
        const centerY = chart.getDatasetMeta(0).data[0].y;
        ctx.save();
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = '16px "Helvetica Neue", Helvetica, Arial, sans-serif';
        ctx.fillText(`${topchain?.Visits}`, centerX, centerY);
      },
    };
  }
  if (topchain?.donut_L4L >= 0) {
    var L4L2 = {
      datasets: [
        {
          data: [topchain.donut_L4L, 100 - topchain.donut_L4L],
          backgroundColor: ["rgb(141, 208, 222)", "#E5E5E5"],
        },
      ],
    };

    var L4L2Lable = {
      id: "L4L2Lable",
      afterDatasetsDraw(chart) {
        const { ctx, data } = chart;
        const centerX = chart.getDatasetMeta(0).data[0].x;
        const centerY = chart.getDatasetMeta(0).data[0].y;
        ctx.save();
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = '16px "Helvetica Neue", Helvetica, Arial, sans-serif';
        ctx.fillText(`${topchain?.L4L}`, centerX, centerY);
      },
    };
  } else {
    var L4L2 = {
      datasets: [
        {
          data: [100 - -topchain?.donut_L4L, topchain?.donut_L4L],
          backgroundColor: ["#E5E5E5", "rgb(141, 208, 222)"],
        },
      ],
    };

    var L4L2Lable = {
      id: "L4L2Lable",
      afterDatasetsDraw(chart) {
        const { ctx, data } = chart;
        const centerX = chart.getDatasetMeta(0).data[0].x;
        const centerY = chart.getDatasetMeta(0).data[0].y;
        ctx.save();
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = '16px "Helvetica Neue", Helvetica, Arial, sans-serif';
        ctx.fillText(`${topchain?.L4L}`, centerX, centerY);
      },
    };
  }
  if (topchain?.donut_Outlets >= 0) {
    var Opening2 = {
      datasets: [
        {
          data: [topchain?.donut_Outlets, 100 - topchain?.donut_Outlets],
          backgroundColor: ["rgb(247, 104, 112)", "#E5E5E5"],
        },
      ],
    };

    var Opening2Lable = {
      id: "Opening2Lable",
      afterDatasetsDraw(chart) {
        const { ctx, data } = chart;
        const centerX = chart.getDatasetMeta(0).data[0].x;
        const centerY = chart.getDatasetMeta(0).data[0].y;
        ctx.save();
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = '16px "Helvetica Neue", Helvetica, Arial, sans-serif';
        ctx.fillText(`${topchain.Outlets}`, centerX, centerY);
      },
    };
  } else {
    var Opening2 = {
      datasets: [
        {
          data: [100 - -topchain?.donut_Outlets, topchain?.donut_Outlets],
          backgroundColor: ["#E5E5E5", "rgb(247, 104, 112)"],
        },
      ],
    };

    var Opening2Lable = {
      id: "Opening2Lable",
      afterDatasetsDraw(chart) {
        const { ctx, data } = chart;
        const centerX = chart.getDatasetMeta(0).data[0].x;
        const centerY = chart.getDatasetMeta(0).data[0].y;
        ctx.save();
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = '16px "Helvetica Neue", Helvetica, Arial, sans-serif';
        ctx.fillText(`${topchain.Outlets}`, centerX, centerY);
      },
    };
  }
  if (topchain?.donut_Prices >= 0) {
    var Prices2 = {
      datasets: [
        {
          data: [topchain.donut_Prices, 100 - topchain.donut_Prices],
          backgroundColor: ["rgb(29, 185, 222)", "#E5E5E5"],
        },
      ],
    };

    var Prices2Lable = {
      id: "Prices2Lable",
      afterDatasetsDraw(chart) {
        const { ctx, data } = chart;
        const centerX = chart.getDatasetMeta(0).data[0].x;
        const centerY = chart.getDatasetMeta(0).data[0].y;
        ctx.save();
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = '16px "Helvetica Neue", Helvetica, Arial, sans-serif';
        ctx.fillText(`${topchain.Prices}`, centerX, centerY);
      },
    };
  } else {
    var Prices2 = {
      datasets: [
        {
          data: [100 - -topchain?.donut_Prices, topchain?.donut_Prices],
          backgroundColor: ["#E5E5E5", "rgb(29, 185, 222)"],
        },
      ],
    };

    var Prices2Lable = {
      id: "Prices2Lable",
      afterDatasetsDraw(chart) {
        const { ctx, data } = chart;
        const centerX = chart.getDatasetMeta(0).data[0].x;
        const centerY = chart.getDatasetMeta(0).data[0].y;
        ctx.save();
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = '16px "Helvetica Neue", Helvetica, Arial, sans-serif';
        ctx.fillText(`${topchain.Prices}`, centerX, centerY);
      },
    };
  }

  if (topchain?.donut_Promo >= 0) {
    var Promo2 = {
      datasets: [
        {
          data: [topchain?.donut_Promo, 100 - topchain?.donut_Promo],
          backgroundColor: ["rgb(79, 231, 177)", "#E5E5E5"],
          label: `%`,
        },
      ],
    };

    var Promo2Lable = {
      id: "Promo2Lable",
      afterDatasetsDraw(chart) {
        const { ctx, data } = chart;
        const centerX = chart.getDatasetMeta(0).data[0].x;
        const centerY = chart.getDatasetMeta(0).data[0].y;
        ctx.save();
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = '16px "Helvetica Neue", Helvetica, Arial, sans-serif';
        ctx.fillText(`${topchain?.absolute_Promo}`, centerX, centerY);
      },
    };
  } else {
    var Promo2 = {
      datasets: [
        {
          data: [100 - -topchain?.donut_Promo, topchain?.donut_Promo],
          backgroundColor: ["#E5E5E5", "rgb(79, 231, 177)"],
          label: `%`,
        },
      ],
    };

    var Promo2Lable = {
      id: "Promo2Lable",
      afterDatasetsDraw(chart) {
        const { ctx, data } = chart;
        const centerX = chart.getDatasetMeta(0).data[0].x;
        const centerY = chart.getDatasetMeta(0).data[0].y;
        ctx.save();
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = '16px "Helvetica Neue", Helvetica, Arial, sans-serif';
        ctx.fillText(`${topchain?.absolute_Promo}`, centerX, centerY);
      },
    };
  }
  if (topchain?.donut_Product >= 0) {
    var Product2 = {
      datasets: [
        {
          data: [topchain?.donut_Product, 100 - topchain.donut_Product],
          backgroundColor: ["rgb(251, 185, 65)", "#E5E5E5"],
          label: `%`,
        },
      ],
    };

    var Product2Lable = {
      id: "Product2Lable",
      afterDatasetsDraw(chart) {
        const { ctx, data } = chart;
        const centerX = chart.getDatasetMeta(0).data[0].x;
        const centerY = chart.getDatasetMeta(0).data[0].y;
        ctx.save();
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = '16px "Helvetica Neue", Helvetica, Arial, sans-serif';
        ctx.fillText(`${topchain?.absolute_Product}`, centerX, centerY);
      },
    };
  } else {
    var Product2 = {
      datasets: [
        {
          data: [100 - -topchain?.donut_Product, topchain?.donut_Product],
          backgroundColor: ["#E5E5E5", "rgb(251, 185, 65)"],
          label: `%`,
        },
      ],
    };

    var Product2Lable = {
      id: "Product2Lable",
      afterDatasetsDraw(chart) {
        const { ctx, data } = chart;
        const centerX = chart.getDatasetMeta(0).data[0].x;
        const centerY = chart.getDatasetMeta(0).data[0].y;
        ctx.save();
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = '16px "Helvetica Neue", Helvetica, Arial, sans-serif';
        ctx.fillText(`${topchain.absolute_Product}`, centerX, centerY);
      },
    };
  }
  const showModal = () => {
    dispatch(showFilterModal());
  };
  const handleOk = () => {
    dispatch(hideFilterModal());
  };
  const handleCancel = () => {
    dispatch(hideFilterModal());
  };
  const token = localStorage.getItem("token");
  return (
    <>
      {loader === true ? (
        <>
          <Skeleton loading={loader} active></Skeleton>
          <Skeleton loading={loader} active></Skeleton>
          <Skeleton loading={loader} active></Skeleton>
        </>
      ) : (
        <div className='layout-content'>
          <Row className='mb-4'>
            <Col span={2}>
              <h6>
                <span
                  style={{
                    color: "#000000",
                    fontSize: "24px",
                    background: "white",
                    padding: "5px",
                    fontFamily: "Quicksand",
                    fontWeight: "600",

                    display: "flex",
                  }}
                  className='mb-2 fw-bold rounded'
                >
                  My chain
                </span>
              </h6>
            </Col>
            <Col span={20} className='text-center'>
              <Space size={[0, "small"]} wrap>
                <Tooltip1 placement='bottomLeft' title={Timescales && Timescales.length > 0 ? Timescales : Timescales}>
                  <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                    Timescales:
                    {Timescales && Timescales.length > 0 ? Timescales : "Not selected"}{" "}
                  </Tag>
                </Tooltip1>
                <Tooltip1
                  placement='bottomLeft'
                  title={
                    MarketSegment && MarketSegment.length > 0
                      ? MarketSegment.map((e) => {
                          return <li>{e}</li>;
                        })
                      : "Not selected"
                  }
                >
                  <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                    Segment:
                    {MarketSegment && MarketSegment.length > 0 ? `${MarketSegment[0]} ${MarketSegment?.length ? MarketSegment.length : ""} ` : "Not selected"}{" "}
                  </Tag>
                </Tooltip1>
                <Tooltip1
                  placement='bottomLeft'
                  title={
                    CompetitiveSet && CompetitiveSet.length > 0
                      ? CompetitiveSet.map((e) => {
                          return <li>{e}</li>;
                        })
                      : "Not selected"
                  }
                >
                  <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                    Competitive:
                    {CompetitiveSet && CompetitiveSet.length > 0 ? `${CompetitiveSet[0]} ${CompetitiveSet?.length > 1 ? CompetitiveSet.length : ""} ` : "Not selected"}{" "}
                  </Tag>
                </Tooltip1>
                <Tooltip1
                  placement='bottomLeft'
                  title={
                    ComparisionType && ComparisionType.length > 0
                      ? ComparisionType.map((e) => {
                          return <li>{e}</li>;
                        })
                      : "Not selected"
                  }
                >
                  <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                    Comparison Type:
                    {ComparisionType && ComparisionType.length > 0 ? `${ComparisionType[0]}  ` : "Not selected"}{" "}
                  </Tag>
                </Tooltip1>
              </Space>
            </Col>
            <Col span={2}>
              <Button onClick={showModal} className='mb-4' icon={<Image src={ViewCustomized} width={14} preview={false} />} size={"large"}>
                Filters
              </Button>
            </Col>
          </Row>

          <>
            <div className='row mb-4'>
              <div className='col-2'>
                <div className='card border-0 shadow-lg'>
                  <div className='card-body'>
                    <div className=' border-0 text-center p-2' style={{ fontSize: "16px", fontFamily: "Quicksand", fontWeight: "600", fontWeight: "600" }}>
                      Visits
                    </div>

                    {mychain && mychain.Visits && <Doughnut data={visits1} plugins={[visits1lable]} />}
                  </div>
                </div>
              </div>
              <div className='col-2'>
                <div className='card border-0 shadow-lg'>
                  <div className='card-body'>
                    <div className=' border-0 text-center p-2' style={{ fontSize: "16px", fontFamily: "Quicksand", fontWeight: "600" }}>
                      L4L Analysis
                    </div>
                    {mychain && mychain.Outlets && <Doughnut data={L4L1} plugins={[L4L1Lable]} />}
                  </div>
                </div>
              </div>
              <div className='col-2'>
                <div className='card border-0 shadow-lg'>
                  <div className='card-body'>
                    <div className=' border-0 text-center p-2' style={{ fontSize: "16px", fontFamily: "Quicksand", fontWeight: "600" }}>
                      Store count
                    </div>
                    {mychain && mychain.Outlets && <Doughnut data={Opening1} plugins={[Opening1Lable]} />}
                  </div>
                </div>
              </div>
              <div className='col-2'>
                <div className='card border-0 shadow-lg'>
                  <div className='card-body'>
                    <div className=' border-0 text-center p-2' style={{ fontSize: "16px", fontFamily: "Quicksand", fontWeight: "600" }}>
                      Prices
                    </div>
                    {mychain && mychain.Prices && mychain.Prices && <Doughnut data={Prices1} plugins={[Prices1Lable]} />}
                  </div>
                </div>
              </div>
              <div className='col-2'>
                <div className='card border-0 shadow-lg'>
                  <div className='card-body'>
                    <div className=' border-0 text-center p-2' style={{ fontSize: "16px", fontFamily: "Quicksand", fontWeight: "600" }}>
                      Promo
                    </div>
                    {mychain && mychain.Promo && <Doughnut data={Promo} plugins={[PromoLable]} />}
                  </div>
                </div>
              </div>
              <div className='col-2'>
                <div className='card border-0 shadow-lg'>
                  <div className='card-body'>
                    <div className=' border-0 text-center p-2' style={{ fontSize: "16px", fontFamily: "Quicksand", fontWeight: "600" }}>
                      Products
                    </div>
                    {mychain && mychain.Product && <Doughnut data={Product1} plugins={[Product1Lable]} />}
                  </div>
                </div>
              </div>
            </div>
            <div className='mb-4'>
              <h6 className='mb-4'>
                <span style={{ color: "#000000", fontSize: "24px", background: "white", padding: "5px", fontFamily: "Quicksand", fontWeight: "600" }} className='mb-4 fw-bold rounded'>
                  Top chain
                </span>
              </h6>
            </div>

            <div className='row'>
              <div className='col-2'>
                <div className='card border-0 shadow-lg'>
                  <div className='card-body'>
                    <div className=' border-0 text-center p-2' style={{ fontSize: "16px", fontFamily: "Quicksand", fontWeight: "600" }}>
                      Visits
                    </div>
                    {topchain && topchain.Visits && <Doughnut data={Visits2} plugins={[Visits2Lable]} />}
                  </div>
                </div>
              </div>
              <div className='col-2'>
                <div className='card border-0 shadow-lg'>
                  <div className='card-body'>
                    <div className=' border-0 text-center p-2' style={{ fontSize: "16px", fontFamily: "Quicksand", fontWeight: "600" }}>
                      L4L Analysis
                    </div>
                    {topchain && topchain.L4L && <Doughnut data={L4L2} plugins={[L4L2Lable]} />}
                  </div>
                </div>
              </div>
              <div className='col-2'>
                <div className='card border-0 shadow-lg'>
                  <div className='card-body'>
                    <div className=' border-0 text-center p-2' style={{ fontSize: "16px", fontFamily: "Quicksand", fontWeight: "600" }}>
                      Store count
                    </div>
                    {topchain && topchain.Outlets && <Doughnut data={Opening2} plugins={[Opening2Lable]} />}
                  </div>
                </div>
              </div>
              <div className='col-2'>
                <div className='card border-0 shadow-lg'>
                  <div className='card-body'>
                    <div className=' border-0 text-center p-2' style={{ fontSize: "16px", fontFamily: "Quicksand", fontWeight: "600" }}>
                      Prices
                    </div>
                    {topchain && topchain.Prices && <Doughnut data={Prices2} plugins={[Prices2Lable]} />}
                  </div>
                </div>
              </div>
              <div className='col-2'>
                <div className='card border-0 shadow-lg'>
                  <div className='card-body'>
                    <div className=' border-0 text-center p-2' style={{ fontSize: "16px", fontFamily: "Quicksand", fontWeight: "600" }}>
                      Promo
                    </div>
                    {topchain && topchain.Promo && <Doughnut data={Promo2} plugins={[Promo2Lable]} />}
                  </div>
                </div>
              </div>
              <div className='col-2'>
                <div className='card border-0 shadow-lg'>
                  <div className='card-body'>
                    <div className=' border-0 text-center p-2' style={{ fontSize: "16px", fontFamily: "Quicksand", fontWeight: "600" }}>
                      Products
                    </div>
                    {topchain && topchain.Product && <Doughnut data={Product2} plugins={[Product2Lable]} />}
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      )}
      <Suspense fallback={<div>Loading...</div>}>
        <FilterModal showModal={showModal} isModalOpen={isModalOpen} handleCancel={handleCancel} handleOk={handleOk} setCalApi={setCalApi} calApi={calApi} name={name} dashboard={"Home"} />
      </Suspense>
    </>
  );
}

export default Home;
